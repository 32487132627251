import { Routes, Route } from 'react-router-dom';
import './App.css';
import About from './components/About';
import Contact from './components/Contact';
import Home from './components/Home';
import Insights from './components/Insights';
import Services from './components/Services';
import zedd from "../src/assets/zedd.jpg"

function App() {
  return (
    <>
    <div className='flex flex-col justify-center items-center gap-3 min-h-screen'>
      <img className='w-28' src={zedd} />
      <h1 className='title'>Coming Soon.</h1>
      <button className='mt-5 btn-primary p-1 w-[285px] lg:w-[350px]' onClick={() => window.open("https://zeddlabz.medium.com/","_blank")}>
        Know More
      </button>
    </div>
      {/* <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/services' element={<Services />} />
        <Route path='/about' element={<About />} />
        <Route path='/insights' element={<Insights />} />
        <Route path='/contact' element={<Contact />} />
      </Routes> */}
    </>
  );
}

export default App;
